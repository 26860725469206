<template>
    <div class="d-flex">
        <activator
        @search="search"
        placeholder="ابحث عن مستخدم محدد"
        class="search-button"
        ></activator>
        <b-button @click="getExcelData" variant="primary" class="mr-1">
            التصدير إلى اكسل
        </b-button>
        <b-button @click="navigateToPrintPage" variant="primary">
            طباعة
        </b-button>
    </div>
</template>

<script>
import Activator from "@/EK-core/components/EK-dialog/activaitor.vue";
import { nullGuid } from "@Ekcore/util/global.js";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  components: {
    Activator,
  },
  computed: {
      ...mapState({
            activeUserTab: (state) =>
                state.reports.activeUserTab,
        }),
    ...mapGetters(["userReportsList", "activityReportsList"]),

  },
  data: () => ({
    nullGuid
  }),
  methods: {
    ...mapActions(["getUserExcelData", "getActivityExcelData"]),
    search(query) {
      this.$store.commit("Set_Search_Dto", {
        keys: ['accountName', 'commercialActivityName', 'userName'],
        query,
      });
    },
    getExcelData() {
        if(this.activeUserTab == 1)
         {
             this.getUserExcelData()
             return
         }
         else
         {
             this.getActivityExcelData()
             return
         }
    },
    navigateToPrintPage() {
        if(this.activeUserTab == 1) 
        {
            localStorage.setItem(
                "userReports",
                JSON.stringify([...this.userReportsList ])
            );

            this.$router.push("/admin/reports/printUserData")
        }
        else
        {
            localStorage.setItem(
                "activityReports",
                JSON.stringify([...this.activityReportsList ])
            );
            this.$router.push("/admin/reports/printActivityData")
        }
    }
  },
};
</script>

<style scoped lang="scss">
    .search-button {
        flex: 1;
    }
</style>